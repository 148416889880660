'use strict';

/*** Header fixed on scroll ***/
var scrollpos = window.pageYOffset;
var header = document.querySelector("header.fixed-top");
window.addEventListener('scroll', function(){
  scrollpos = window.pageYOffset;
  if(scrollpos > 10){
    header.classList.add("scroll-fix");
  }
  else {
    header.classList.remove("scroll-fix");
  }
});

$(document).ready(function () {

  /*** WOW init ***/
  wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: true,
    live: true
  })
  wow.init();

  headerResize();
  funSearchBar();
  funInputPlaceholder();

  /*** Lottie animation for Header Icon ***/
  let iconMenu = document.getElementById('iconHamburger');
  let btnMenu = document.querySelector('.navbar-toggler');
  let btnClose = document.querySelector('.close-icon');
  let modelCloseBtn = document.querySelector('.close-icon-model');
  let btnSearch = document.querySelector('.btn-search');

  let animationMenu = bodymovin.loadAnimation({
    container: iconMenu,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/img/hamburger-on.json'
  });
  var directionMenu = 1;
  btnMenu.addEventListener('mouseenter', function (e) {
    animationMenu.setDirection(directionMenu);
    animationMenu.play();
  });
  btnMenu.addEventListener('mouseleave', function (e) {
    animationMenu.setDirection(-directionMenu);
    animationMenu.play();
  });

  /* Button Close */
  let animationClose = bodymovin.loadAnimation({
    container: btnClose,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/img/close.json'
  });
  btnClose.addEventListener('mouseenter', function (e) {
    animationClose.setDirection(directionMenu);
    animationClose.play();
  });
  btnClose.addEventListener('mouseleave', function (e) {
    animationClose.setDirection(-directionMenu);
    animationClose.play();
  });

  /* Search bar Icon */
  let animationSearch = bodymovin.loadAnimation({
    container: btnSearch,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/img/search-icon2.json'
  });
  btnSearch.addEventListener('mouseenter', function (e) {
    animationSearch.setDirection(directionMenu);
    animationSearch.play();
  });
  btnSearch.addEventListener('mouseleave', function (e) {
    animationSearch.setDirection(-directionMenu);
    animationSearch.play();
  });

  /* Box with Images OLD */
   var arrowSocialcounter=1;
  $(".arrow-social").each(function(  ) {
    //let arrowIconsocial = document.getElementById('arrowSocial'+arrowSocialcounter);
    let arrowIconsocial = $(this)[0];
    let animationArrowsocial = bodymovin.loadAnimation({
      container: arrowIconsocial,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/img/arrow-white.json'
    });
	if(arrowIconsocial!=null){
		arrowIconsocial.addEventListener('mouseenter', function (e) {
      animationArrowsocial.setDirection(directionMenu);
      animationArrowsocial.play();
    });
    arrowIconsocial.addEventListener('mouseleave', function (e) {
      animationArrowsocial.setDirection(-directionMenu);
      animationArrowsocial.play();
    }); 
	}
    
    arrowSocialcounter++;
  }); 
 /* Modal cross btn */
  var modalimgBox=1;
  $(".close").each(function(  ) {
    //let boxArrow = document.getElementById('imgBox'+imgBox);
    let modalboxArrow = $(this)[0];
    let modalboxArrowHover = bodymovin.loadAnimation({
      container: modalboxArrow,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/img/close.json'
    });
	if(modalboxArrow!=null){
      modalboxArrow.addEventListener('mouseenter', function (e) {
      modalboxArrowHover.setDirection(directionMenu);
      modalboxArrowHover.play();
    });
      modalboxArrow.addEventListener('mouseleave', function (e) {
      modalboxArrowHover.setDirection(-directionMenu);
      modalboxArrowHover.play();
    });
	}
     
  modalimgBox++;
  });

  /* Box with Images NEW */
   var imgBox=1;
  $(".box-with-image").each(function(  ) {
    //let boxArrow = document.getElementById('imgBox'+imgBox);
    let boxArrow = $(this)[0];
    let boxArrowHover = bodymovin.loadAnimation({
      container: boxArrow,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/img/arrow-white.json'
    });
	if(boxArrow!=null){
		boxArrow.addEventListener('mouseenter', function (e) {
		boxArrowHover.setDirection(directionMenu);
		boxArrowHover.play();
    });
			boxArrow.addEventListener('mouseleave', function (e) {
		  boxArrowHover.setDirection(-directionMenu);
		  boxArrowHover.play();
    });
	}
     
    imgBox++;
  });

  /******** NEWS box ********/
  var insight_svg1=1;
  $(".cmn-box-inner").each(function(  ) {
    //var lottieDiv="<div id='divinsight_svg"+insight_svg+"'></div>";
    //$(this).append(lottieDiv);
    //let arrowIcon1 = document.getElementById('hoverArrow-news'+insight_svg1);
    let arrowIcon1 = $(this)[0];
    let animationArrow1 = bodymovin.loadAnimation({
      container: arrowIcon1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/img/arrow-white.json'
    });
	if(arrowIcon1!=null){
		arrowIcon1.addEventListener('mouseenter', function (e) {
      animationArrow1.setDirection(directionMenu);
      animationArrow1.play();
    });
    arrowIcon1.addEventListener('mouseleave', function (e) {
      animationArrow1.setDirection(-directionMenu);
      animationArrow1.play();
    }); 
	}
    
    insight_svg1++;
  });

  /******** Primary-arrow ********/
  var primary_svg=1;
  $(".btn-primary-link").each(function(  ) {
    //let PrimaryarrowIcon = document.getElementById('primaryArrow'+primary_svg);
    let PrimaryarrowIcon = $(this)[0];
    let primaryanimationArrow = bodymovin.loadAnimation({
      container: PrimaryarrowIcon,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/img/primary-arrow.json'
    });
	if(PrimaryarrowIcon!=null){
		PrimaryarrowIcon.addEventListener('mouseenter', function (e) {
      primaryanimationArrow.setDirection(directionMenu);
      primaryanimationArrow.play();
    });
    PrimaryarrowIcon.addEventListener('mouseleave', function (e) {
      primaryanimationArrow.setDirection(-directionMenu);
      primaryanimationArrow.play();
    }); 
	}
    
    primary_svg++;
  });

  /******** Secondary Plus ********/
  var secondary_svg=1;
  $(".btn-secondary-link").each(function(  ) {
   //let SecondaryarrowIcon = document.getElementById('secondaryPlus'+secondary_svg);
   let SecondaryarrowIcon = $(this)[0];
    let secondaryanimationArrow = bodymovin.loadAnimation({
      container: SecondaryarrowIcon,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/img/secondary-plus.json'
    });
      if (SecondaryarrowIcon != null) {
          SecondaryarrowIcon.addEventListener('mouseenter', function (e) {
              secondaryanimationArrow.setDirection(directionMenu);
              secondaryanimationArrow.play();
          });
          SecondaryarrowIcon.addEventListener('mouseleave', function (e) {
              secondaryanimationArrow.setDirection(-directionMenu);
              secondaryanimationArrow.play();
          });
      }
    secondary_svg++;
  });

   /* Career page icons */
   var Careercounter=1;
    $(".for-arrow").each(function(  ) {
        let arrowIconcareer = document.getElementById('arrowCareer'+Careercounter);
        let animationArrowcareer = bodymovin.loadAnimation({
        container: arrowIconcareer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/primary-arrow.json'
        });
        if (arrowIconcareer != null) {
            arrowIconcareer.addEventListener('mouseenter', function (e) {
                animationArrowcareer.setDirection(directionMenu);
                animationArrowcareer.play();
            });
            arrowIconcareer.addEventListener('mouseleave', function (e) {
                animationArrowcareer.setDirection(-directionMenu);
                animationArrowcareer.play();
            });
        }
        Careercounter++;
    }); 

    /* Social commitment page icons */
   var socialBoxcounter=1;
   $(".social-box").each(function(  ) {
      // let arrowIconsocialBox = document.getElementById('arrowsocialBox'+socialBoxcounter);
      let arrowIconsocialBox = $(this)[0];
       let animationArrowsocialBox = bodymovin.loadAnimation({
       container: arrowIconsocialBox,
       renderer: 'svg',
       loop: false,
       autoplay: false,
       path: '/img/arrow-white.json'
       });
       if (arrowIconsocialBox != null) {
           arrowIconsocialBox.addEventListener('mouseenter', function (e) {
               animationArrowsocialBox.setDirection(directionMenu);
               animationArrowsocialBox.play();
           });
           arrowIconsocialBox.addEventListener('mouseleave', function (e) {
               animationArrowsocialBox.setDirection(-directionMenu);
               animationArrowsocialBox.play();
           });
           socialBoxcounter++;
       }
   });

   /* Social commitment bottom navigation */
   var socialBoxnavigate=1;
   $(".social-navigation-block").each(function(  ) {
       let arrowIconsocialNavigate = document.getElementById('arrowsocialNavigate'+socialBoxnavigate);
       let animationArrowsocialNavigate = bodymovin.loadAnimation({
       container: arrowIconsocialNavigate,
       renderer: 'svg',
       loop: false,
       autoplay: false,
       path: '/img/primary-arrow.json'
       });
       if (arrowIconsocialNavigate != null) {
           arrowIconsocialNavigate.addEventListener('mouseenter', function (e) {
               animationArrowsocialNavigate.setDirection(directionMenu);
               animationArrowsocialNavigate.play();
           });
           arrowIconsocialNavigate.addEventListener('mouseleave', function (e) {
               animationArrowsocialNavigate.setDirection(-directionMenu);
               animationArrowsocialNavigate.play();
           });
       }
       socialBoxnavigate++;
   });
  
  /*** Img to SVG ***/
  jQuery("img.svg").each(function() {
    var $img = jQuery(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");
    jQuery.get(
      imgURL,
      function(data) {
        var $svg = jQuery(data).find("svg");
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }
        $svg = $svg.removeAttr("xmlns:a");
        if (
          !$svg.attr("viewBox") &&
          $svg.attr("height") &&
          $svg.attr("width")
        ) {
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }
        $img.replaceWith($svg);
      },
      "xml"
    );
  });

  /*** Select 2 ***/
  $('.cmn-select select').on('select2:open', function () {
    funSelectScroll();
  });

  $(document).on('keydown', '.select2-search__field', function () {
    funSelectScroll();
  });

  $(".inner-subnav a[href^='#']").on('click', function (e) {
    e.preventDefault();
    var hash = this.hash;
    $('html, body').animate({
        scrollTop: $(hash).offset().top
    }, 1500, function () {
    });
  });

  /**Scroll Down Arrow**/
  $(".hp-banner-arrow").click(function() {
    var headerHeight = $('header'). outerHeight();
    var nextSection = $(this).closest("section").next().offset().top - headerHeight;
    $("html, body").animate({scrollTop: nextSection}, "slow");
  });
});

/*** Header: toggle ***/
function headerResize() {
  $(".navbar-toggler").click(function (e) {
    e.preventDefault();
    $('header, body').css('padding-right', (window.innerWidth - $('body').width()) + 'px');
	  $('body').css('overflow', 'hidden');
    $('#divNavbar').toggleClass("collapse-in");
    $('header').toggleClass("add-overlay");
  });
  $(".close-icon").click(function(e){
    e.preventDefault();
    $(".navbar-toggler").trigger("click");
    $('header').removeClass("add-overlay");
    $('header, body').css('padding-right', '');
    $('body').css('overflow', 'auto');
    $(".navbar-nav li.has-children").removeClass('active');
    // $(".mCSB_container").removeClass('active');
  });
  $(".header-overlay").click(function(e){
    e.preventDefault();
    $(".navbar-toggler").trigger("click");
    $('header, body').css('padding-right', '');
    $('body').css('overflow', 'auto');
    $(".navbar-nav li.has-children").removeClass('active');
    // $(".mCSB_container").removeClass('active');
  });
  setTimeout(function() {
    $('#mainMenu, #mainMenu .sub-menu').mCustomScrollbar({
      axis: 'y', 
      scrollbarPosition: 'inside',
      advanced:{
        updateOnContentResize: true,
      },
    });
  }, 1000);

  $(".navbar-nav li.has-children > a").click(function () {
    $(this).parent().toggleClass('active');
    $(this).parent().parent().toggleClass('active');
  });

  $(".navbar-nav li.has-children > a").click(function () {
    event.preventDefault();
  });
  
  $(".navbar-nav li.has-children .sub-menu .back").click(function () {
    event.preventDefault();
    $(this).closest(".has-children").removeClass('active');
    // $(".mCSB_container").removeClass('active');
  }); 

}

/*** Header: Searchbar ***/
function funSearchBar() {
  $('#searchBar .btn-search').on('click', function(event) {
    event.stopPropagation();
    if($('#searchBar').hasClass('open-search')) {
      $("header").removeClass("show-search");
      $("#searchBar").removeClass("open-search");
      $("#stickyContent").css("opacity", "1");
    } else {
      $("#fieldSearch").attr("data-empty","false");
      $("#fieldSearch").focus();
      $("header").addClass("show-search");
      $("#searchBar").addClass("open-search");
      $("#stickyContent").css("opacity", "0");
    }
  });

  $('#searchBar .btn-close').on('click', function(event) {
    event.stopPropagation();
    $('#fieldSearch').attr("data-empty", true).val('');
    $("#autocomplete-data .mCSB_container").html('');
    $("header").removeClass("show-search");
    $("#searchBar").removeClass("open-search");
    $("#stickyContent").css("opacity", "1");
  });

  $(document).click(function() {
    var container = $("#searchBar");
    var closeSearch = $("#searchBar .btn-close");
    if (!container.is(event.target) && !container.has(event.target).length) {
      $("header").removeClass("show-search");
      container.removeClass("open-search");
      $("#stickyContent").css("opacity", "1");
    }
  });
};
function searchClearResult(){
  $('#fieldSearch').attr("data-empty", true).val('');
  $("#autocomplete-data .mCSB_container").html('');

};

/**  Select2 Custom Scrollbar **/
function funSelectScroll() {
  $('.select2-dropdown .select2-results__options').mCustomScrollbar('destroy');
  setTimeout(function () {
    $('.select2-dropdown .select2-results__options').mCustomScrollbar({
      axis: 'y',
      scrollbarPosition: 'inside',
      advanced: {
        updateOnContentResize: true,
      },
    });
  }, 8);
}
  
function funInputPlaceholder() {
  $(".form-group").find("input,textarea").each(function () {
    if ($.trim($(this).val()) != "")
      $(this).attr("data-empty", !$(this).val());
  });
  $(".form-group").find("select").each(function () {
    if ($.trim($(this).val()).toString().indexOf("?") < 0)
      $(this).attr("data-empty", !$(this).val());
  });
  $(".form-group").find("input,textarea").on("input", function (e) {
    $(e.currentTarget).attr("data-empty", !e.currentTarget.value);
  });
  $(".form-group").find("select").on("change", function (e) {
    $(e.currentTarget).attr("data-empty", !e.currentTarget.value);
  });
}

function funResetInputPlaceholder() {
  $(".form-group").find("input,textarea").each(function () {
    if ($.trim($(this).val()) == "") $(this).attr("data-empty", true);
  });
  $(".form-group").find("select").each(function () {
    if ($.trim($(this).val()).toString().indexOf("?") > 0)
      $(this).attr("data-empty", true);
  });
}

/*** WOW SHOW/HIDE ON SCROLL ***/
WOW.prototype.addBox = function(element) {
this.boxes.push(element);
};
var wow = new WOW();
wow.init();

$('.wow').on('scrollSpy:exit', function() {
  $(this).css({
  'visibility': 'hidden',
  'animation-name': 'none'
  }).removeClass('animated');
  wow.addBox(this);
}).scrollSpy();


/*** Footer toggle ***/
$(".loc-title h2").append("<span></span>");
$(function () {
  $('.loc-title h2').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('clicked');
    $('.address-list').slideToggle();
  });
})

$('.loc-title').on('click', function (e) {
  if($('.loc-title h2').hasClass('clicked')){
    var $panel = $(this);
    var $headerheight = $('header').outerHeight(true);
    $('html,body').animate({
        scrollTop: $panel.offset().top - $headerheight - 10
    }, 500);
  }
});

$('.accord-block').on('shown.bs.collapse', function (e) {
  var $panel = $(this).closest('.accord-block');
  var $headerheight = $('header').outerHeight(true);
  $('html,body').animate({
    scrollTop: $panel.offset().top - $headerheight - 10
  }, 500);
}).on('hidden.bs.collapse', function (e) {
  var $panel = $(this).closest('.accord-block');
  $('html,body').animate({
    scrollTop: $panel.offset().top - 1
  }, 400);
});
